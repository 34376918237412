.chat-box {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #fff;
}

.room-label {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-top: 1em;
}

.chat-content {
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 5em;
  padding-bottom: 6em;
}

.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
}

.avatar {
  background-color: #3498db;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 10px;
}

.message-data {
  background-color: #444;
  border-radius: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
  max-width: 45vw;
  padding: 10px 15px;
  word-wrap: break-word;
}

.chat-top {
  position: fixed;
  width: 100%;
  height: 1.5cm;
  background-color: #1a1a1a;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.9);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.message.self .message-data {
  background-color: #444;
  color: #fff;
}

.send-message {
  display: flex;
  border: #fff;
  border-radius: 25px;
  background-color: #1d1d1d;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
  align-items: center;
  width: 95vw;
  max-width: 700px;

}

.chat-form {
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 60vw;
  padding: 10px 0;
  margin-left: 20vw;
}

.input-message {
  border: none;
  border-radius: 25px;
  color: #ffffff;
  background-color: rgb(59, 59, 59);
  padding: 10px;
  width: 100%;
  resize: none; /* Prevent manual resizing */
  overflow: auto; /* Show scrollbar when max height is reached */
  text-overflow: ellipsis;
  word-break: break-word;
  text-wrap: wrap;
  max-height: 60vh; /* Set a maximum height */
  box-sizing: border-box; /* Include padding in height */
  font-size: 15px;
}

.input-message:focus {
  outline: none;
}

.input-message::placeholder {
  color: #999; /* Placeholder text color */
}



.leave-button {
  background-color: inherit;
  width: 40px;
  height: 45px;
  margin: 10px;
  transition: 0.3s;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover,
.leave-button:hover {
  transform: scale(1.1);
}

.leave-button:active {
  transform: scale(0.9);
}

.time {
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  float: right;
}

.message.self {
  justify-content: flex-end;
}

.message.notification {
  justify-content: center;
  margin: 2px;
}

.message.self .message-data {
  align-self: flex-end;
  margin-right: 8px;
}

.message.notification .message-data {
  align-self: center;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.message:not(.self):not(.notification) {
  justify-content: flex-start;
}

.message:not(.self) .message-data {
  align-self: flex-start;
}

.content-message {
  max-width: 45vw;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.message-time {
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  float: right;
}

.message.notification.date-stamp {
  display: flex;
  width: fit-content;
  margin: 10px auto;
  border: 1px solid #fff;
  border-radius: 15px;
}

.message.notification.date-stamp p {
  margin: 0;
  padding: 3px 15px;
}

.content-message p {
  margin: 3px;
  padding-left: 10px;
}

.message-fullname {
  color: #a9dcff;
}

.file-upload-button {
  color: #fff;
  cursor: pointer;
  width: 40px;
  height: 45px;
}

.media-content img,
.media-content video {
  padding: 10px;
  width: 40vw;
  height: auto;
}

.file-send-button {
  transition: 0.3s;
  opacity: 1;
}

.file-uploads {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.file-icon span {
  margin-left: 5px;
}

.record-button {
  color: #fff;
  cursor: pointer;
  width: 40px;
  height: 45px;
}
.send-button{
  background-color: transparent;
  height: 45px;
  transition: 0.3s;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-progress {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 5px;
}

.progress-bar {
  height: 5px;
  background-color: #76c7c0;
  transition: width 0.3s;
}
.media-content a {
  color: #3498db; /* A more vibrant color for better visibility */
  text-decoration: none;
  background-color: #2c2c2c; /* Slightly lighter background for contrast */
  padding: 5px 10px; /* Increased padding for better spacing */
  border-radius: 8px; /* More rounded corners */
  margin: 5px 0; /* Adjusted margin for spacing between links */
  text-overflow: ellipsis;
  word-break: break-word; /* Changed to 'break-word' for better word wrapping */
  display: inline-block;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.media-content a:hover {
  background-color: #3a3a3a; /* Darker background on hover */
  color: #fff; /* Change text color to white on hover */
}

