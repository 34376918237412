.signup {
  /* Aligning to the center of the page */
  padding: 30px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  color: white;
  background-color: rgba(0, 116, 155, 0.3);
}


  img{
    width: 40px;
    height: 40px;
    margin-top: 40px;
  }

  .user-registration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-weight: bold;
    background-image: url("");
  }

  @media (max-width: 767px) {
    .user-registration {
      background-image: url("../../public/Essentials/background.svg");
    }
  }

  @media (min-width: 768px) {
    .user-registration {
      background-image: url("../../public/Essentials/background.svg");
      /* background-color:darkslateblue; */
    }
  }
