.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loaderContainer {
  background: rgba(150, 150, 150, 0.9);
  color: white;
  padding: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.bubbles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: white;
  animation: bounce 1.5s infinite ease-in-out;
}

.bubble:nth-child(1) {
  animation-delay: -0.32s;
}

.bubble:nth-child(2) {
  animation-delay: -0.16s;
}

.loaderMessage {
  text-align: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
  max-width: 300px;
  word-wrap: break-word;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1);
  }
}